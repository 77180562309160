import http from "@/services/http";
import { HOME_PAGE_BY_ROLE, ROUTER_NAMES } from "@/router/router.enum";

const formatData = (provider, code, { microsoft, google }, token = null) => {
  const data = {
    code: code,
    token: token,
  };
  if (!token) delete data.token;
  if (provider === "microsoft") {
    data.provider = "microsoft-graph";
    data.redirect_uri = microsoft.redirectUri;
  }
  if (provider === "google") {
    data.provider = "google-oauth2";
    data.redirect_uri = google.redirectUri;
  }
  return data;
};

const getTokenViaSocialAuth = async (
  { router, route, store },
  { provider, code, socialAuthProviders, token }
) => {
  try {
    let data = formatData(provider, code, socialAuthProviders, token);
    const response = await http.fetchTokensBySocialAuth(data);

    if (response.url) {
      window.location.href = response.url;
      return;
    }

    const user = await store.dispatch("fetchUser");

    localStorage.removeItem("token-for-password-creation");

    if (route.query.from && route.query.from.includes("supplier")) {
      router.push({ path: route.query.from });
      return;
    }

    router.push({ name: HOME_PAGE_BY_ROLE[user.role] });
  } catch (err) {
    triggerError(router, err);
  }
};

const triggerError = (router, message = null) => {
  router.push({
    name: ROUTER_NAMES.TRY_AGAIN,
    query: { error_message: message },
  });
};

export { getTokenViaSocialAuth, triggerError };
