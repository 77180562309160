<template>
  <div class="flex items-center flex-col pt-13 pb-7 relative">
    <span>Please wait, we will close this page automatically</span>
  </div>
</template>

<script>
import {
  triggerError,
  getTokenViaSocialAuth,
} from "@/services/api/social-auth-api";
import { useRouter, useRoute } from "vue-router";
import { onMounted, inject } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const socialAuthProviders = inject("socialAuthProviders");
    const errorQuery = route.query.error;
    const codeQuery = route.query.code;
    const provider = route.params.provider;

    const token = route.query.token || null;

    onMounted(() => {
      // Microtask cuz this page can be closed
      setTimeout(() => {
        if (errorQuery) {
          triggerError(router);
          return;
        }
        getTokenViaSocialAuth(
          {
            router,
            route,
            store,
          },
          {
            provider,
            code: codeQuery,
            socialAuthProviders,
            token,
          }
        );
      }, 2000);
    });

    return {};
  },
};
</script>
